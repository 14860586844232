import React, {useEffect, useRef} from "react"
import {gsap} from "gsap";
import Logo from "../images/logo_white.png";

const Confirm = () => {

    let confirmImage = useRef(null);
    let confirmTitle = useRef(null);
    let confirmSubTitle = useRef(null);
    let confirmText = useRef(null);

    useEffect(() => {
        gsap.from(confirmImage, 0.8, {
            opacity: 0,
            scale: 1.6,
            ease: "Power2.easeInOut",
            delay: 0
        });
        gsap.from(confirmTitle, 0.4, {
            opacity: 0,
            y:32,
            delay:0.1
        });
        gsap.from(confirmSubTitle, 0.4, {
            opacity: 0,
            y: 64,
            delay:0.1
        });
        gsap.from(confirmText, 0.4, {
            opacity: 0,
            y: 80,
            delay:0.1
        });
    }, [confirmImage, confirmTitle, confirmSubTitle, confirmText]);


    return (
        <section className="confirm-page">
            <div className="text-wrapper">
                <h1 ref={el => (confirmTitle = el)}>Thank You!</h1>
                <p ref={el => (confirmSubTitle = el)}>Your message has been sent successfully.</p>
                <p ref={el => (confirmText = el)}>We will reply to you soon.</p>
            </div>
            <div className="img-wrapper">
                <img ref={el => (confirmImage = el)} src={Logo} alt="Rockaholik"/>
            </div>
        </section>
    );
}

export default Confirm
