import React from "react"

import Index from "../components/layout"
import Confirm from "../components/confirm";

const Thankyou = () => {

    return (
        <Index title="Thank You">
            <Confirm/>
        </Index>
    )
}


export default Thankyou
